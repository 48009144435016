import classNames from "classnames";
import { useState, type FC, type JSX, useRef } from "preact/compat";
import { REGEX_EMAIL } from "../../utils/constants.ts";
import ReCaptcha, { type RecaptchaRef } from "@components/recaptcha/recaptcha.tsx";
import s from "./EmailForm.module.scss";
import { sendEmailForFollowNotification } from "@lib/tangem.ts";
import type { EmailDetails } from "../../types/dom";

type Props = {
  className?: string;
  policyText: string;
  confirmedText: string;
  placeholder: string;
  successPlaceholderText: string;
  validationErrorText: string;
  serverErrorText: string;
  formId: string;
  size?: "normal" | "large" | "tiny";
  variant?: "transparent" | "default";
  isPolicyCentered?: boolean;
  action: string;
};

const secret = import.meta.env.PUBLIC_STORE_RECAPTCHA_SECRET;

export const EmailForm: FC<Props> = ({
  className,
  policyText,
  confirmedText,
  placeholder,
  successPlaceholderText,
  validationErrorText,
  formId,
  serverErrorText,
  action,
  size = "normal",
  variant = "default",
  isPolicyCentered = false,
}) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasVadationError, setHasVadationError] = useState(false);
  const [hasServerError, setHasServerError] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const recaptchaRef = useRef<RecaptchaRef>(null);

  const handleSubmit: JSX.SubmitEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (isLoading) return;
    setHasServerError(false);

    const formData = new FormData(e.currentTarget);
    const email = formData.get("email")?.toString().trim();
    const token = await recaptchaRef.current?.execute();

    if (!email || !token) {
      return;
    }

    if (!REGEX_EMAIL.test(email)) {
      setHasVadationError(true);
      return;
    }

    setIsLoading(true);

    try {
      const res = await sendEmailForFollowNotification({
        email,
        token,
        action,
      });
      if ((res as Response).status >= 400 || (res as Response).status >= 599) {
        setHasServerError(true);
      } else {
        setHasServerError(false);
        setIsSuccess(true);
        document.dispatchEvent(
          new CustomEvent<EmailDetails>("email-form-sent", {
            detail: {
              action,
            },
          }),
        );
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      }
    } catch (error) {
      setHasServerError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInput = () => {
    setHasVadationError(false);
    setHasServerError(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames(s.form, className)}
      noValidate
    >
      <div
        className={classNames(s.form__field, {
          [s.form__field_large]: size === "large",
          [s.form__field_tiny]: size === "tiny",
        })}
      >
        <input
          id={`${formId}_email_input`}
          type="email"
          name="email"
          maxLength={50}
          autoComplete="off"
          required
          className={classNames(s.form__input, {
            [s.form__input_large]: size === "large",
            [s.form__input_transparent]: variant === "transparent",
            [s.form__input_invalid]: hasVadationError,
          })}
          onInput={handleInput}
          placeholder={isSuccess ? successPlaceholderText : placeholder}
          disabled={isSuccess || isLoading}
          ref={inputRef}
          aria-invalid={hasVadationError}
        />
        <button
          type="submit"
          aria-label="Subscribe"
          className={classNames(s.form__submit, {
            [s.form__submit_success]: isSuccess,
            [s.form__submit_large]: size === "large",
            [s.form__submit_loading]: isLoading,
          })}
          disabled={isSuccess}
        ></button>
      </div>
      {hasVadationError || hasServerError ? (
        <span
          id={`${formId}-error`}
          className={classNames(s.form__label, {
            [s.form__label_tiny]: size === "tiny",
          })}
        >
          <span className={s.form__label_invalid}>
            {hasVadationError ? validationErrorText : ""}
            {hasServerError ? serverErrorText : ""}
          </span>
        </span>
      ) : null}
      <ReCaptcha
        sitekey={secret}
        size="invisible"
        render="explicit"
        theme="dark"
        badge="none"
        version="v3"
        recaptchaId={`captcha_email-${formId}`}
        ref={recaptchaRef}
      />
      {isSuccess ? (
        <p
          className={classNames(s.form__policy, {
            [s.form__policy_centered]: isPolicyCentered,
            [s.form__policy_tiny]: size === "tiny",
          })}
        >
          {confirmedText}
        </p>
      ) : (
        <p
          className={classNames(s.form__policy, {
            [s.form__policy_centered]: isPolicyCentered,
            [s.form__policy_tiny]: size === "tiny",
            [s.form__policy_large]: size === "large",
          })}
          dangerouslySetInnerHTML={{
            __html: policyText.replaceAll(
              /\[([^\]]+)\]\(([^)]+)\)/g,
              '<a href="$2">$1</a>',
            ),
          }}
        />
      )}
    </form>
  );
};
